import React, { Suspense, useEffect, useState } from "react";

import "react-progress-2/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Progress from "react-progress-2";
import { BrowserRouter as Router } from "react-router-dom";

import { GlobalStyle } from "../styles/components/globalStyle";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "../styles/components/themes";
import ScrollToTop from "../components/ScrollToTop";
import { Toaster } from "react-hot-toast";
import PublicRouter from "../router";
import Header from "../frontend/layouts/header";
import Footer from "../frontend/layouts/footer";
import PopupComponent from "../components/popup";
import Preloader from "../components/common/Preloader";
import { LogoContextProvider } from "../stores/LogoContext";

const App = () => {
      const [loading, setLoading] = useState(true);

      useEffect(() => {
            setTimeout(() => {
                  setLoading(false);
            }, 4500);
      }, []);

      return (
            <>
                  <GlobalStyle />
                  <Progress.Component
                        style={{ background: "#99999978", height: "5px" }}
                        thumbStyle={{
                              background: "rgb(232, 107, 25)",
                              height: "5px",
                        }}
                  />
                  <Preloader />
                  {/* {loading && <Preloader />} */}
                  <ThemeProvider theme={lightTheme}>
                        <Router>
                              <LogoContextProvider>
                                    <ScrollToTop />
                                    <Toaster position="bottom-center" />
                                    <PopupComponent />
                                    {/* <Header /> */}
                                    <Suspense fallback="Loading">
                                          {/* <PublicRouter /> */}
                                    </Suspense>
                                    {/* <Footer /> */}
                              </LogoContextProvider>
                        </Router>
                  </ThemeProvider>
            </>
      );
};

export default App;
